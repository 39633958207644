import React from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import { down, up } from 'styled-breakpoints';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components/macro';
import Container from 'react-bootstrap/Container';
import BaseCol from 'react-bootstrap/Col';
import Button from 'components/Button';
import RichText from 'components/RichText';
import PrismicLink from 'components/PrismicLink';
import carouselShape from 'shapes/differentiatorShape';
import PropTypes from 'prop-types';

export const slickArrowCss = css`
  .slick-arrow {
    &.slick-prev {
      left: 1rem;
      z-index: 100;

      &:before {
        content: '\\2039';
      }

      ${down('md')} {
        left: 1rem;
      }
    }

    &.slick-next {
      right: 6rem;

      &:before {
        content: '\\203A';
      }

      ${down('md')} {
        right: 1.45rem;
      }

      ${down('sm')} {
        right: 0.75rem;
      }
    }

    &.slick-prev,
    &.slick-next {
      top: 35%;
      height: 3rem;
      width: 3rem;
    }

    &:before {
      color: ${theme('colors.lightGray')};
      font-size: 10rem;
    }

    &.slick-disabled {
      display: none !important;
    }
  }
`;

const propTypes = {
  data: carouselShape.isRequired,
  className: PropTypes.string,
};

const SliderContainer = styled.div`
  margin: 0;

  ${slickArrowCss}

  .slick-track {
    min-height: 13.75rem;
  }

  .slick-slide {
    min-height: 12.5rem;

    ${up('sm')} {
      padding: 0 0.625rem;
    }

    > div {
      min-height: inherit;
    }
  }

  .slick-dots {
    display: none !important; /* overriding inline style from react-slick */
  }
`;

const CarouselContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Tile = styled.div.attrs((props) => ({
  className: 'bg-dark',
}))`
  align-items: center;
  color: ${theme('colors.white')};
  display: flex !important; /* overriding inline style from react-slick */
  height: 100%;
  min-height: 20rem;
  padding: 1rem 0;

  ${down('sm')} {
    margin-bottom: 0;
  }

  ${up('sm')} {
    min-height: 24rem;
  }

  ${up('md')} {
    min-height: 32rem;
  }

  ${ifProp(
    'img',
    css`
    background-image: url("${prop('img')}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
  `
  )}

  h1 {
    color: ${theme('colors.primary')};

    ${down('md')} {
      font-size: 2.25rem;
    }

    ${down('xs')} {
      font-size: 1.5rem;
    }
  }
`;

const InnerContainer = styled(Container)`
  ${down('sm')} {
    max-width: 80%;
  }
`;

const Col = styled(BaseCol)`
  ${up('sm')} {
    max-width: 80%;
  }

  ${down('sm')} {
    padding: 0;
  }
`;

const CarouselSlider = styled(Slider)`
  ${down('sm')} {
    max-width: 100%;
    margin: 0 auto;
  }
`;

function Carousel({ data, className }) {
  if (!data) {
    return null;
  }

  const { carouselTitle, commentaryBody, commentaryHeader } = data.primary;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: false,
    autoplay: false,
    speed: 500,
    cssEase: 'linear',
    pauseOnHover: false,
  };

  return (
    <CarouselContainer className={className}>
      <Container>
        {carouselTitle && <RichText render={carouselTitle} />}
        {commentaryHeader && <RichText render={commentaryHeader} />}
        {commentaryBody && <RichText render={commentaryBody} />}
      </Container>
      <SliderContainer>
        {data.fields && (
          <CarouselSlider {...settings}>
            {data.fields.map((field, index) => {
              const { title, body, image, ctaLink, ctaText, ctaStyle } = field;

              return (
                <Tile
                  img={image && image.url}
                  key={`carousel-feature-${index}`}
                >
                  <InnerContainer className='h-100'>
                    <Col
                      xs={12}
                      md={12}
                      className='d-flex flex-column justify-content-center h-100'
                    >
                      {title && <RichText render={title} />}
                      {body && <RichText render={body} />}
                      {ctaLink && ctaText && (
                        <PrismicLink
                          link={ctaLink}
                          internalAs={ctaStyle === 'Link' ? Link : Button}
                          internalProps={{
                            as: ctaStyle === 'Link' ? Link : Button,
                          }}
                          externalAs={ctaStyle === 'Link' ? 'a' : Button}
                          externalProps={{
                            target: '_blank',
                          }}
                        >
                          {ctaText}
                        </PrismicLink>
                      )}
                    </Col>
                  </InnerContainer>
                </Tile>
              );
            })}
          </CarouselSlider>
        )}
      </SliderContainer>
    </CarouselContainer>
  );
}

Carousel.propTypes = propTypes;

export default Carousel;
