import pick from 'lodash/pick';
import get from 'lodash/get';

const PAGE_STYLE_FIELDS = ['pageBackgroundColor', 'pageBodyTextColor'];

const SOCIAL_META_FIELDS = [
  'ogTitle',
  'ogImage',
  'ogDescription',
  'twitterCard',
  'twitterImage',
  'twitterTitle',
  'twitterDescription',
];

function findDefaultPageImage(doc) {
  // Get possible image fields for all page types.
  const {
    mastheadImage,
    image,
    largeThumbnail,
    mediumThumbnail,
    thumbnail,
  } = doc;

  return (
    mastheadImage || image || largeThumbnail || mediumThumbnail || thumbnail
  );
}

export function extractPageStyles(doc) {
  return pick(doc, PAGE_STYLE_FIELDS);
}

export function extractSocialMeta(doc) {
  const socialMeta = pick(doc, SOCIAL_META_FIELDS);

  const defaultPageImage = findDefaultPageImage(doc);

  // Default og:image to the twitter:image or the defaultPageImage if there is one.
  if (!socialMeta.ogImage && (socialMeta.twitterImage || defaultPageImage)) {
    socialMeta.ogImage = socialMeta.twitterImage || defaultPageImage;
  }

  // Default twitter:image to the og:image if there is one.
  if (!socialMeta.twitterImage && socialMeta.ogImage) {
    socialMeta.twitterImage = socialMeta.ogImage;
  }

  // If the document has a youtube URL, then we should force the twitter card type to "player".
  const youtubeUrl = get(doc, 'youtubeUrl.url');
  if (youtubeUrl) {
    socialMeta.twitterCard = 'player';
    socialMeta.twitterPlayer = youtubeUrl.replace('watch?v=', 'embed/');
    socialMeta.twitterPlayerWidth = '1280';
    socialMeta.twitterPlayerHeight = '720';
  }

  return socialMeta;
}
