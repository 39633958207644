import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import { prop, theme } from 'styled-tools';
import { slickArrowCss } from '../Slices/Carousel';

const StyledSlider = styled(Slider)`
  ${down('md')} {
    max-width: 90%;
    margin: 0 auto;
  }

  ${down('sm')} {
    max-width: 80%;
  }

  ${up('lg')} {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  ${slickArrowCss} {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: 45%;
        height: 100%;
        width: inherit;

        &:before {
          line-height: inherit;
        }
      }

      &.slick-prev {
        left: -2.5rem;

        ${up('md')} {
          left: -4rem;
        }
      }

      &.slick-next {
        right: -2.5rem;

        ${up('md')} {
          right: -4rem;
        }
      }

      &:before {
        color: ${theme('colors.muted')};

        ${down('sm')} {
          font-size: 5rem;
        }
      }
    }
  }

  .slick-track {
    min-height: 13.75rem;
  }

  .slick-slide {
    ${up('sm')} {
      padding: 0 0.625rem;
    }

    > div {
      min-height: inherit;
    }
  }

  .slick-dots {
    bottom: -1.875rem;

    li {
      margin: 0 0.1875rem;
    }

    button {
      &:before {
        font-size: 0.5625rem;
      }
    }
  }
`;

const DEFAULT_SETTINGS = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  accessibility: false,
  autoplay: false,
  autoplaySpeed: 0,
  pauseOnHover: false,
  speed: 500,
  swipe: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        autoplay: false,
        speed: 200,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        autoplay: false,
        speed: 700,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        autoplay: true,
        speed: 700,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 700,
      },
    },
  ],
};

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  settings: PropTypes.object,
};

function Carousel({ children, type, settings = {} }) {
  const isDifferentiator = type === 'differentiator';

  const combinedSettings = {
    ...DEFAULT_SETTINGS,
    autoplay: isDifferentiator,
    pauseOnHover: isDifferentiator,
    speed: isDifferentiator ? 5000 : 500,
    ...settings,
  };

  return (
    <StyledSlider type={type} {...combinedSettings}>
      {children}
    </StyledSlider>
  );
}

Carousel.propTypes = propTypes;

export default Carousel;
