import { graphql } from 'gatsby';

export const DoughAppLinkFragment = graphql`
  fragment DoughAppLinkFragment on DOUGHAPP__Linkable {
    _linkType
    ... on DOUGHAPP__ExternalLink {
      url
    }
    ... on DOUGHAPP__FileLink {
      url
      name
    }
    ... on DOUGHAPP__ImageLink {
      url
      name
    }
  }
`;
