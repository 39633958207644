import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    number_of_posts: string,
    learn_block_title: prismicRichTextShape,
    learn_block_description: prismicRichTextShape,
    card_background: string,
    card_text: string,
  }),
  fields: arrayOf(
    shape({
      category: string
    })
  )
});
