import PropTypes from 'prop-types';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { arrayOf, number, shape, string } = PropTypes;

export default shape({
  extendedDescription: prismicRichTextShape,
  image: prismicImageShape,
  meta: shape({
    lang: string.isRequired,
    tags: arrayOf(string).isRequired,
    type: string.isRequired,
    uid: string.isRequired,
  }),
  ogImage: string,
  ogTitle: string,
  ogDescription: string,
  publishDate: string,
  seoMeta: string,
  seoTitle: string,
  sortOrder: number,
  symbols: arrayOf(
    shape({
      symbol: string
    })
  ),
  title: prismicRichTextShape,
  twitterCard: string,
  twitterDescription: string,
  twitterImage: string,
  twitterTitle: string,
  webCategory: string,
  webDescription: prismicRichTextShape,
});
