import React from 'react';
import PropTypes from 'prop-types';
import Article from 'components/Slices/LearnBlock/Article';
import Carousel from 'components/Carousel';
import discoveryVideoShape from 'shapes/discoveryVideoShape';
import discoveryArticleShape from 'shapes/discoveryArticleShape';
import styled from 'styled-components/macro';
import BaseRow from 'react-bootstrap/Row';
import { down, up } from 'styled-breakpoints';
import BaseCol from 'react-bootstrap/Col';

const Row = styled(BaseRow)`
  ${down('sm')} {
    flex-direction: column;
  }
`;

const Col = styled(BaseCol)`
  ${up('md')} {
    max-width: 50%;
  }

  ${up('lg')} {
    max-width: 33%;
  }
`;

const CAROUSEL_SETTING_OVERRIDES = {
  infinite: false,
  slidesToScroll: 4,
  speed: 200,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: false,
        speed: 200,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        autoplay: false,
        speed: 200,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        speed: 200,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 700,
        speed: 200,
      },
    },
  ],
};

const propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.oneOfType([discoveryVideoShape, discoveryArticleShape])
  ),
  cardBackground: PropTypes.string,
  cardText: PropTypes.string,
};

function ArticleList({ articles, cardBackground, cardText }) {
  return (
    <div>
      {articles.length <= 3 ? (
        <Row md={articles.length}>
          {articles.map((result, index) => (
            <Col>
              <Article
                article={result.node}
                cardBackground={cardBackground}
                cardText={cardText}
                key={`article-${index}`}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Carousel settings={CAROUSEL_SETTING_OVERRIDES}>
          {articles.map((result, index) => (
            <Article
              article={result.node}
              cardBackground={cardBackground}
              cardText={cardText}
              key={`article-${index}`}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
}

ArticleList.propTypes = propTypes;

export default ArticleList;
