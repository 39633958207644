import React from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { ifProp, prop, theme } from 'styled-tools';
import { H4, P as BaseP } from 'components/Typography';
import styled, { css } from 'styled-components/macro';
import ArticleImage from './ArticleImage';
import RichText from 'components/RichText';
import linkResolver from 'utils/linkResolver';
import { VIDEO, ARTICLE } from 'utils/constants';
import { formatDate } from 'utils/functions';
import learnBlockArticleShape from 'shapes/learnBlockArticleShape';

const StyledArticle = styled(Link)`
  display: block;
  background-color: ${ifProp('background', prop('background'), prop('theme.colors.white'))};
  border-radius: 0.1rem;
  margin-bottom: 1rem;
  height: 100%;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.2);

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  p {
    color: ${ifProp('color', prop('color'), prop('theme.colors.black'))};
  }
`;

const P = styled(BaseP)`
  ${ifProp(
    { type: 'category' },
    css`
      margin-bottom: 0;
    `
  )}
`;

const ArticleDetails = styled.div`
  min-height: 3rem;
  padding: 1rem 1rem 0 1rem;
`;

const ArticleExcerpt = styled.div`
  /* Adds ellipses if more than three lines of text which */
  /* would otherwise overflow the panel */
  display: -webkit-box;
  overflow: hidden;
  padding: 0 1rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  p {
    margin-bottom: 0;
  }
`;

// PropTypes will be updated when this work is flushed out in follow-up PR
const propTypes = {
  article: learnBlockArticleShape,
  cardBackground: PropTypes.string,
  cardText: PropTypes.string,
};

function Article({ article, cardBackground, cardText }) {
  const articleType = article.meta.type;
  const publishDate =
    article.publishDate || get(article, 'meta.firstPublicationDate');

  return (
    <StyledArticle
      background={cardBackground}
      color={cardText}
      to={linkResolver(article.meta)}
    >
      {articleType === ARTICLE && article.image && (
        <ArticleImage image={article.image} />
      )}
      {articleType === VIDEO && article.mediumThumbnail && (
        <ArticleImage image={article.mediumThumbnail} />
      )}
      <ArticleExcerpt>
        {article.description && <RichText render={article.description} />}
      </ArticleExcerpt>
      <ArticleDetails>
        {publishDate && <P>{formatDate(publishDate)}</P>}
      </ArticleDetails>
    </StyledArticle>
  );
}

Article.propTypes = propTypes;

export default Article;
