import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import concat from 'lodash/concat';
import PropTypes from 'prop-types';
import { switchProp } from 'styled-tools';
import { down } from 'styled-breakpoints';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components/macro';
import { getCursorFromDocumentIndex } from 'gatsby-source-prismic-graphql';
import { H3 } from 'components/Typography';
import RichText from 'components/RichText';
import BaseContainer from 'react-bootstrap/Container';
import learnBlockShape from 'shapes/learnBlockShape';
import { filterAllResults } from 'utils/functions';
import { DoughAppLinkFragment } from 'graphql-fragments/DoughAppLinkFragment';
import appPrismicShape from 'shapes/appPrismicShape';
import ArticleList from './ArticleList';

const Container = styled(BaseContainer)`
  margin-top: 2rem;
  margin-bottom: 2rem;

  ${down('sm')} {
    padding: 1.25rem;
  }

  ${switchProp('type', {
    ['static']: css`
      margin-bottom: 5rem;
      margin-top: 5rem;
    `,
    ['carousel']: css`
      margin-bottom: 5rem;
      margin-top: 5rem;
    `,
  })}
`;

const discoverPostsQuery = graphql`
  query DiscoverPostList(
    $first: Int = 20
    $last: Int
    $after: String
    $before: String
    $tags_in: [String!]
    $tags: [String!] = ["display_on_web"]
    $similar: DOUGHAPP_similar
  ) {
    appPrismic {
      allDiscoveryArticles: allDiscovery_articles(
        first: $first
        last: $last
        after: $after
        before: $before
        sortBy: meta_firstPublicationDate_DESC
        tags: $tags
        tags_in: $tags_in
        similar: $similar
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            image
            title
            description: extended_description
            publishDate: publish_date
            webCategory: web_category_name
            webSortOrder: web_sort_order
            meta: _meta {
              lang
              tags
              type
              uid
              firstPublicationDate
            }
          }
        }
      }
      allDiscoveryVideos: allDiscovery_videos(
        first: $first
        last: $last
        after: $after
        before: $before
        sortBy: meta_firstPublicationDate_DESC
        tags: $tags
        tags_in: $tags_in
        similar: $similar
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            title
            description: extended_description
            publishDate: publish_date
            webCategory: web_category_name
            webSortOrder: web_sort_order
            meta: _meta {
              lang
              tags
              type
              uid
              firstPublicationDate
            }
            thumbnail: thumbnail_url {
              ...DoughAppLinkFragment
            }
            mediumThumbnail: medium_thumbnail_url {
              ...DoughAppLinkFragment
            }
          }
        }
      }
    }
  }
`;

function buildQueryVariables(categories, page, data) {
  const queryVariables = { tags_in: categories };

  if (page !== -1) {
    queryVariables.after = getCursorFromDocumentIndex(page);
  }

  if (data.similar) {
    queryVariables.similar = data.similar;
  }
  return queryVariables;
}

function handleDataUpdate(page, setDiscoveryData, res, discoveryData) {
  if (page === -1) {
    // If the page is reset due to hot-reload, override the posts instead of concatenating them.
    setDiscoveryData(res.data);
  } else {
    setDiscoveryData({
      ...res.data,
      allDiscoveryVideos: {
        ...res.data.allDiscoveryVideos,
        edges: get(discoveryData, 'allDiscoveryVideos.edges', []).concat(
          get(res, 'data.allDiscoveryVideos.edges', [])
        ),
      },
      allDiscoveryArticles: {
        ...res.data.allDiscoveryArticles,
        edges: get(discoveryData, 'allDiscoveryArticles.edges', []).concat(
          get(res, 'data.allDiscoveryArticles.edges', [])
        ),
      },
    });
  }
}

function canLoadMorePosts(maxPosts, sortedResults, res) {
  return (
    (null === maxPosts || sortedResults.length < maxPosts) &&
    (get(res.data, 'allDiscoveryVideos.pageInfo.hasNextPage', false) ||
      get(res.data, 'allDiscoveryArticles.pageInfo.hasNextPage', false))
  );
}

const propTypes = {
  data: learnBlockShape.isRequired,
  className: PropTypes.string,
  appPrismic: appPrismicShape.isRequired,
};

function LearnBlock({ data, className, appPrismic }) {
  const categories = map(get(data, 'fields', []), 'category').filter(
    (item) => item
  );
  const maxPosts = get(data, 'primary.number_of_posts', null);

  // Limit must be 20 or less due to limitation in prismic graphql API.
  const perPageLimit = 20;
  const [page, setPage] = useState(-1);
  const [discoveryData, setDiscoveryData] = useState(null);

  const allResults = concat(
    get(discoveryData, 'allDiscoveryVideos.edges', []),
    get(discoveryData, 'allDiscoveryArticles.edges', [])
  );

  const sortedResults = filterAllResults(allResults, categories, data);

  useEffect(() => {
    if (appPrismic && appPrismic.load) {
      const queryVariables = buildQueryVariables(categories, page, data);

      appPrismic
        .load({
          query: discoverPostsQuery,
          variables: queryVariables,
          fragments: [DoughAppLinkFragment],
        })
        .then((res) => {
          handleDataUpdate(page, setDiscoveryData, res, discoveryData);

          // If there are more to load and we haven't hit a maxPosts limit, let's load next page.
          if (canLoadMorePosts(maxPosts, sortedResults, res)) {
            setPage(page + perPageLimit);
          }
        });
    }
  }, [page]);

  const finalResults =
    maxPosts > 0 ? sortedResults.splice(0, maxPosts) : sortedResults;

  if (!discoveryData || !data || finalResults.length === 0) {
    return null;
  }

  const cardBackground = get(data.primary, 'card_background');
  const cardText = get(data.primary, 'card_text');

  return (
    <Container
      type={finalResults.length <= 3 ? 'static' : 'carousel'}
      className={className}
    >
      {get(data.primary, 'section_title') ? (
        <H3>{data.primary.section_title}</H3>
      ) : (
        <RichText render={get(data.primary, 'learn_block_title')} />
      )}
      {data.primary && <RichText render={get(data.primary, 'learn_block_description')} />}

      <ArticleList
        articles={finalResults}
        cardBackground={cardBackground}
        cardText={cardText}
      />
    </Container>
  );
}

LearnBlock.propTypes = propTypes;

export default LearnBlock;
