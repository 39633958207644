import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import prismicImageShape from 'shapes/prismicImageShape';

const { shape, string, arrayOf } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    title: prismicRichTextShape,
  }),
  fields: arrayOf(
    shape({
      icon: prismicImageShape,
      hoverIcon: prismicImageShape,
      header: prismicRichTextShape,
      body: prismicRichTextShape,
      iconAlignment: string,
      iconSize: string,
    })
  ),
});
