import React from 'react';
import styled from 'styled-components/macro';
import prismicImageShape from 'shapes/prismicImageShape';
import { getImgAlt } from 'utils/functions';

const ImageContainer = styled.div`
  margin-bottom: 1rem;

  img {
    width: 100%;
    border-top-right-radius: 0.1rem;
    border-top-left-radius: 0.1rem;
  }
`;

const propTypes = {
  image: prismicImageShape,
};

function ArticleImage({ image }) {
  return (
    <ImageContainer>
      <img alt={getImgAlt(image)} src={image.url} />
    </ImageContainer>
  );
}

ArticleImage.propTypes = propTypes;

export default ArticleImage;
