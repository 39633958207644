import PropTypes from 'prop-types';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { number, shape, string, arrayOf } = PropTypes;

export default shape({
  description: prismicRichTextShape,
  image: prismicImageShape,
  mediumThumbnail: prismicImageShape,
  publishDate: string,
  thumbnail: prismicImageShape,
  title: prismicRichTextShape,
  webCategory: string,
  webSortOrder: number,
  meta: {
    lang: string.isRequired,
    tags: arrayOf(string).isRequired,
    type: string.isRequired,
    uid: string.isRequired,
  }
});
